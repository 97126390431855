.App {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.313rem 3.75rem;
  overflow: hidden;
}

.content {
  margin: 0em -1rem -1rem -1rem;
  padding: 0rem 1rem 1rem 1rem;
  height: 100%;
  overflow-y: auto;
}

h1 {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin: 0 0 1.25rem 0;
}

h2 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin: 0 0 0.5rem 0;
}

.ms-Spinner {
  display: flex;
  align-self: center;
  justify-self: center;
}

.ms-Spinner-label {
  color: var(--n4it-gray);
}

@media only screen and (max-width: 1440px) {
  .content-wrapper {
    padding: 2rem 1.875rem;
  }
}


@media only screen and (max-width: 768px) {
  .content-wrapper {
    height: calc(100% - var(--mobile-nav-height));
    padding: 2rem 1.5rem;
    margin-top: var(--mobile-nav-height);
    overflow-y: unset;
  }

  .content {
    overflow-y: unset;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 0 0 1rem 0;
  }

  h2 {
    line-height: 1.5rem;
    margin: 0 0 .3125rem 0;
  }
}
