.time-input {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.time-input input {
    cursor: pointer;
    caret-color: transparent;
}

.time-panel {
    height: 16.25rem;
    width: auto;
    overflow-x: hidden;
    position: absolute;
    top: 4.25rem;
    left: 0;
    display: none;
    background-color: white;
    flex-direction: row;
    padding: .5rem;
    box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
    border-radius: 0.3125rem;
    z-index: 2;
}

.time-panel .hours {
    margin-right: .25rem;
    overflow-y: auto;
}

.time-panel .hours::-webkit-scrollbar {
    display: none;
}

.time-panel .hours div, .time-panel .minutes div {
    padding: .5rem .75rem;
    width: 2.75rem;
    height: 2.5rem;
    cursor: pointer;
    border-radius: .3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-panel .hours div:hover, .time-panel .minutes div:hover {
    background-color: var(--stone-gray);
    color: white;
}

.time-panel .hours div.selected, .time-panel .minutes div.selected {
    background-color: var(--stone-gray);
    color: white;
}

.time-panel .hours .header-label, .time-panel .minutes .header-label {
    font-size: .625rem;
    color: var(--stone-gray);
    pointer-events: none;
    cursor: default;
}

.displayPanel {
    display: flex;
}