/********* DetailsList *********/
.ms-Viewport {
    margin-top: 1.5625rem;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
}

.ms-Viewport::-webkit-scrollbar  {
    display: none;
}

.ms-DetailsList, .ms-DetailsList {
    height: 100%;
    overflow-x: hidden;
}

.ms-DetailsList .ms-DetailsList-contentWrapper {
    height: 100%;
    /* overflow-y: auto; */
}

.ms-DetailsHeader {
    padding: 0;
    background-color: var(--pearl-gray);
    box-sizing: border-box;
    height: 3.2rem;
    line-height: normal;
}

.ms-DetailsHeader-cell {
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.ms-DetailsHeader-cell > span{
    position: relative;
}

.ms-DetailsHeader-cellTitle {
    padding-left: 0;
} 

.ms-DetailsRow:hover {
    background-color: var(--lilia-gray);
}

.ms-DetailsRow-cell {
    height: 3.75rem;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.ms-DetailsRow-cell .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ms-DetailsRow-check {
    height: auto;
    opacity: .5;
    justify-content: center;
}

/********* ShimmeredDetailsList *********/
.ms-Shimmer-shimmerWrapper {
    background-color: var(--lilia-gray);
}