.create-allocation-content .ms-TextField.is-disabled div{
    border: none;
}

.create-allocation-content .secondary-btn {
    width: 100%;
    justify-content: center;
}

.create-allocation-content .secondary-btn .ms-Button-textContainer {
    flex-grow: 0;
}

.create-allocation-content .action-btns {
    display: flex;
    margin-top: 3.125rem;
}

.create-allocation-content .tertiary-btn, .create-allocation-content .primary-btn {
    flex: 1;
}

.create-allocation-content .tertiary-btn {
    margin-right: 1.25rem;
}
