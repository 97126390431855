.tertiary-btn {
    background: none;
    color: var(--n4it-gray);
    border: .0625rem solid var(--n4it-gray);
}

.tertiary-btn:hover:enabled {
    opacity: .8;
    background-color: var(--n4it-gray);
    color: var(--white);
}