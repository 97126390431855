.allocate-hours-popup {
  width: 100%;
}

.allocate-hours-popup .card-header .titles .subtitle {
  display: flex;
  font-size: 12px;
  color: var(--stone-gray);
  margin-left: 0.75rem;
}

.allocate-hours-popup .card-header .titles .subtitle.holiday {
  color: #dd9d00;
}

.allocate-hours-popup .content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 25rem;
  min-height: 25rem;
  margin: 0;
  padding: 0;
}

.editPopup-loading {
  height: 100%;
}

.allocate-hours-popup .content .working-time {
  margin-bottom: 0.9375rem;
}

.allocate-hours-popup .content .working-time .working-time-inputs {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 9.6875rem);
  column-gap: 1.25rem;
  align-items: end;
}

.allocate-hours-popup .content .working-time .working-time-inputs .remove-required .ms-TextField-fieldGroup::before {
  content: "";
}

.allocate-hours-popup .content .project-hours .project-inputs {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 15.15625rem 15.15625rem 9.6875rem;
  grid-template-rows: auto;
  grid-template-areas:
    "project activity hours"
    "textfield textfield add";
  column-gap: 1.25rem;
  align-items: end;
}

.allocate-hours-popup .content .project-hours .project-inputs .notes {
  grid-area: textfield;
  margin-bottom: 0;
}

.allocate-hours-popup .content .project-hours .project-inputs .project-disabled .ms-Dropdown-label {
  opacity: 0.5;
}

.allocate-hours-popup .content .project-hours .project-inputs .activity-disabled .ms-TextField-fieldGroup {
  border: none;
}

.allocate-hours-popup .content .project-hours .project-inputs .secondary-btn {
  width: 100%;
  height: 100%;
}

.allocate-hours-popup .content .project-hours .project-inputs .secondary-btn .ms-Button-textContainer {
  flex-grow: 0;
}

.allocate-hours-popup .content .project-hours .ms-Viewport {
  margin-top: 0.9375rem;
}

.allocate-hours-popup .content .project-hours .project-hours-list .project-info {
  display: flex;
  flex-direction: column;
}

.allocate-hours-popup .content .project-hours .project-hours-list .project-info .activity {
  font-size: 0.75rem;
  color: var(--stone-gray);
  line-height: normal;
  margin-top: -0.3rem;
}

.allocate-hours-popup .content .project-hours .ms-DetailsHeader,
.allocate-hours-popup .content .project-hours .ms-DetailsRow-cell {
  height: 2.75rem;
}

.allocate-hours-popup .content .project-hours .ms-Viewport .notes-icon {
  color: var(--n4it-gray);
  font-size: 0.875rem;
  cursor: default;
}

.allocate-hours-popup .content .project-hours .ms-Viewport button {
  color: var(--n4it-gray);
}

.allocate-hours-popup .action-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 9.6875rem);
  column-gap: 1.25rem;
  margin-top: 1.5rem;
}

.allocate-hours-popup .action-btns .total-hours {
  font-size: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--stone-gray);
  line-height: 1rem;
}

.allocate-hours-popup .action-btns .primary-btn {
  grid-column: 4;
}

.allocate-hours-popup .action-btns .tertiary-btn {
  grid-column: 3;
}

@media only screen and (max-width: 768px) {
  .allocate-hours-popup .card-header .titles .subtitle {
    margin-left: 0;
  }

  .allocate-hours-popup .content {
    height: 60vh;
    min-height: 60vh;
  }

  .allocate-hours-popup .content .working-time .working-time-inputs {
    grid-template-columns: repeat(2, 1fr);
  }

  .allocate-hours-popup .content .project-hours .project-inputs {
    grid-template-columns: repeat(1, 100%);
    display: block;
  }

  .allocate-hours-popup .content .project-hours .project-inputs .notes {
    margin-bottom: 0.9375rem;
  }

  .allocate-hours-popup .content .project-hours .project-inputs .secondary-btn {
    height: 2.3rem;
  }

  .allocate-hours-popup .action-btns {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "label label";
  }

  .allocate-hours-popup .action-btns .total-hours {
    grid-area: label;
    margin-bottom: 20px;
  }

  .allocate-hours-popup .action-btns .primary-btn {
    grid-column: auto;
  }

  .allocate-hours-popup .action-btns .tertiary-btn {
    grid-column: auto;
  }
}
