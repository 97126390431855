.cv-info-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.875rem;
}
  
.cv-info-wrapper .cv-btns {
    min-width: 33%;
}

.cv-info-wrapper .cv-info {
    flex-grow: 2;
}

@media only screen and (max-width: 768px) {
    .cv-info-wrapper {
        flex-direction: column;
    }
  }