.primary-btn {
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    color: var(--white);
    border: none;
}


.primary-btn:hover:enabled {
    opacity: .8;
}


.primary-btn i {
    color: var(--white);
}
