.item-allocate {
    border: .0313rem solid var(--pearl-gray);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.item-allocate:hover {
    cursor: pointer;
    border: .0313rem solid var(--n4it-gray);
}

.item-allocate:hover .action-text {
    color: var(--n4it-gray);
}

.item-allocate:hover circle {
    fill: var(--n4it-gray);
}

.item-allocate .day {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.item-allocate .allocate-time-icon {
    align-items: center;
    display: flex;
}

.item-allocate .action-text {
    color: var(--stone-gray);
    font-size: .75rem;
    line-height: 1.25rem;
    text-align: center;
    padding-top: .3125rem;
}

@media only screen and (max-width: 1440px){
    .item-allocate {
        padding: 0.8rem;
    }

    .item-allocate .day {
        top: 0.8rem;
        right: 0.8rem;
        font-size: .875rem;
    }

    .item-allocate .action-text {
        display: none;
    }
}