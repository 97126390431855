.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.6rem;
}

.header .header-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

.header .header-account {
    flex-grow: 1;
}

.header .header-info h1 {
    margin-bottom: .3125rem;
}

.header .header-account .account {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.header .header-account .account .primary-btn {
    margin-right: 2.5rem;
    height: 2.25rem;
}

.header .header-account .account .primary-btn .ms-Button-label {
    font-weight: 600;
}

.header .header-account .account .account-menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header .header-account .account .account-menu a:hover {
    opacity: .7;
}

.header .header-account .account .account-menu .avatar {
    display: block;
    margin-right: 0.938rem;
}

.ms-ContextualMenu-Callout .contextual-menu {
    padding: .5rem .75rem .5rem .5rem;
    color: var(--n4it-gray);
}

.ms-ContextualMenu-Callout .contextual-menu .contextual-menu-name {
    font-weight: 600;
}

.ms-ContextualMenu-Callout .contextual-menu .contextual-menu-mail {
    font-size: .75rem;
    line-height: 1rem;
    color: var(--stone-gray);
}

.ms-ContextualMenu-Callout .ms-ContextualMenu-link svg {
    width: 1.625rem;
    height: 1.375rem;
}

@media only screen and (max-width: 768px) {
    .header {
        margin-bottom: 1.25rem;
    }
}