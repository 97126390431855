.item {
    background-color: var(--lilia-gray);
}

.item .item-info {
    display: flex;
    overflow: hidden;
}

.item .item-info .projects {
    padding-right: .3125rem;
    flex-grow: 1;
}

.item .item-info .projects .project-info {
    display: flex;
    line-height: normal;
    margin-bottom: .4375rem;
}

.item .item-info .projects .project-info .project-hour {
    min-width: 0.9375rem;
    width: .9375rem;
    height: .9375rem;
    border-radius: 3.125rem;
    background-color: var(--n4it-gray);
    color: var(--white);
    font-size: .625rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .4375rem;
}

.item .item-info .projects .project-info .project {
    font-size: .75rem;
    display: grid;
}

.item .item-info .projects .project-info .project .project-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.item .item-info .projects .project-info .project .project-activity {
    font-size: .6875rem;
    color: var(--stone-gray);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.item .item-info .day {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.item .item-action-bar {
    display: flex;
    justify-content: space-between;
}

.item .item-action-bar .more-info-btn {
    justify-self: flex-start;
    margin-bottom: -0.3125rem;
    margin-left: -0.3125rem;
}

.item .item-action-bar button {
    padding: 0;
    background: none;
    border: none;
    display: flex;
}

.item .item-action-bar button:hover {
    opacity: .5;
    cursor: pointer;
}

.item .item-action-bar .clone-btn {
    justify-self: flex-end;
    margin-bottom: -0.3125rem;
    margin-right: -0.3125rem;
}

@media only screen and (max-width: 1440px) {
    .item .item-info .projects .project-info .project-hour {
        margin-right: .25rem;
    }

    .item .item-info .day {
        font-size: .875rem;
    }

    .item {
        padding: 0.8rem;
    }
 }