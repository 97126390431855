.clone-hours-popup {
    width: 17.5rem;
}

.clone-hours-popup .card-content {
    overflow-y: hidden;
}

.clone-hours-popup .card-content::before, .clone-hours-popup .card-content::after {
    content: none;
}

.clone-hours-popup .content .action-btns {
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr); 
    column-gap: 1.25rem;
    margin-top: 2.5rem;
}

.clone-hours-popup .content .action-btns .primary-btn {
    padding: 0 .5rem;
}

