.item.selected {
    /* background: linear-gradient(180deg, rgba(234, 12, 71, .4) 0%, rgba(234, 12, 71, 0.2) 100%); */
    background-color: var(--stone-gray);
    color: white;
}

.item.selected .item-info .projects .project-info .project .project-activity {
    color: white;
}

.item.selected .item-action-bar svg circle {
    stroke: white;
}

.item.selected .item-action-bar svg path {
    fill: white;
}

.item:hover {
    cursor: pointer;
    border: .0313rem solid var(--n4it-gray);
}

.item .item-info {
    display: flex;
    overflow: hidden;
}

.item .item-info .projects {
    padding-right: .3125rem;
    flex-grow: 1;
}

.item .item-info .projects .project-info {
    display: flex;
    line-height: normal;
    margin-bottom: .4375rem;
}

.item .item-info .projects .project-info .project-hour {
    min-width: 0.9375rem;
    width: .9375rem;
    height: .9375rem;
    border-radius: 3.125rem;
    background-color: var(--n4it-gray);
    color: var(--white);
    font-size: .625rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .4375rem;
}

.item .item-info .projects .project-info .project {
    font-size: .75rem;
    display: grid;
}

.item .item-info .projects .project-info .project .project-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.item .item-info .projects .project-info .project .project-activity {
    font-size: .6875rem;
    color: var(--stone-gray);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.item .projects.total-hours {
    display: inline-flex;
}

.item .projects .hours {
    width: 1.7rem;
    height: 1.7rem;
    background-color: var(--n4it-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    font-weight: 600;
    color: var(--white);
    margin-bottom: .125rem;
}

.item .item-info .day {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.item .item-action-bar {
    display: flex;
    justify-content: space-between;
}

.item .item-action-bar .more-info-btn {
    justify-self: flex-start;
    margin-bottom: -0.3125rem;
    margin-left: -0.3125rem;
    pointer-events: auto;
}

.item .item-action-bar button {
    padding: 0;
    background: none;
    border: none;
    display: flex;
}

.item .item-action-bar button:hover {
    opacity: .5;
    cursor: pointer;
}


.item .item-action-bar .ms-TooltipHost {
    align-self: end;
    pointer-events: auto;
    cursor: default;
}

.item .item-action-bar .ms-Persona {
    width: .9375rem;
    height: .9375rem;
    display: unset;
}

.item .item-action-bar .ms-Persona .ms-Persona-imageArea, .item .item-action-bar .ms-Persona .ms-Persona-initials {
    width: .9375rem;
    height: .9375rem;
    font-size: .5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .item .item-info .projects .project-info .project-hour {
        margin-right: .25rem;
    }

    .item .item-info .day {
        font-size: .875rem;
    }

    .item {
        padding: 0.8rem;
    }
}

@media only screen and (max-width: 1024px) {
    .item .projects.total-hours {
        display: initial;
    }
 }