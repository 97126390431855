/********* Buttons *********/
.ms-Button-label {
    font-weight: 400;
}

.ms-Button.is-disabled {
    opacity: .7;
    background: var(--pearl-gray);
    color: var(--stone-gray);
    border: none;
    pointer-events: none;
}

.is-disabled i {
    color: var(--stone-gray);
}

.primary-btn, .secondary-btn, .tertiary-btn, .icon-btn {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    border-radius: .3125rem;
    height: 2.3rem;
    padding: 0 .7rem;
}

.btn-margin-right {
    margin-right: 1.25rem;
}

.btn-margin-left {
    margin-left: 1.25rem;
}

@media only screen and (max-width: 768px) {
    .remove-text {
        padding: 0;
        min-width: 2.5rem;
    }

    .remove-text .ms-Button-textContainer {
        display: none;
    }
}