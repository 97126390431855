.card-header {
    width: 100%;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
    border-bottom: .0625rem solid var(--pearl-gray);
    padding-bottom: .75rem;
}

.card-header .titles {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.card-header .titles h1 {
    margin: 0;
}

.card-header .close-icon {
    display: flex;
    align-items: center;
}

.card-header .close-icon button{
    color: var(--n4it-gray);
}

.card-header .close-icon button:hover{
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .card-header .titles {
        flex-direction: column;
    }
 }