.avatar-empty-response {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-empty-response img {
  width: 15rem;
  height: auto;
}
