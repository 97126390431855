.projects-list-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projects-list-card .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
}

.projects-list-card .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.projects-list-card .commandbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projects-list-card .commandbar .search-input {
  min-width: 13.75rem;
}

.projects-list-card .commandbar .search-input.is-disabled .ms-TextField-fieldGroup {
  border: none;
}

.projects-list-card .commandbar .action-btns {
  display: flex;
}

.projects-list-card .commandbar .action-btns .icon-btn:hover path {
  fill: var(--white);
}

.projects-list-card .commandbar .action-btns .ms-CommandBar {
  padding: 0;
  height: 2.3rem;
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
  border-radius: 0.3125rem;
  min-width: 2.3rem;
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled {
  background: none;
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled i {
  color: var(--stone-gray);
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled path {
  fill: var(--stone-gray);
}

.projects-list-card .commandbar .action-btns .ms-CommandBar i {
  color: var(--n4it-gray);
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-OverflowSet-item {
  margin-right: .375rem;
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-OverflowSet-item:last-child {
  margin-right: 0;
}

.projects-list-card .commandbar .action-btns .ms-CommandBar .ms-OverflowSet-item .refresh-cache i {
  font-size: .875rem;
}

.projects-list-card .projects-list .ms-DetailsRow {
  cursor: pointer;
}

.projects-list-card .projects-list .ms-DetailsRow-cell {
    height: 2.8rem;
}

.projects-list-card .projects-list .ms-DetailsRow-cell .disable {
  color: var(--stone-gray);
  opacity: 0.6;
}

.projects-list-card .projects-list .ms-Button--icon:hover {
  opacity: 0.7;
  background: none;
}

.projects-list-card .projects-list .ms-Button--icon i {
  color: var(--n4it-gray);
}

.projects-list-card .projects-list .ms-Button--icon.is-disabled {
  background: none;
}

.projects-list-card .projects-list .ms-Button--icon.is-disabled i {
  color: var(--stone-gray);
}

@media only screen and (max-width: 768px) {
  .project-info-card .project-info {
    flex-direction: column;
  }

  .project-info-card .project-info .info-block {
    margin-right: 0;
  }

  .project-info-card .project-info .info-block:last-child {
    margin-bottom: 0;
  }

  .projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
    background-color: var(--n4it-gray);
  }

  .projects-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar i {
    color: var(--white);
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .projects-list-card .commandbar .search-input {
    min-width: auto;
    flex: 1;
    margin-right: .375rem;
  }
}
