.my-equipments {
  height: 100%;
}

.my-equipments .device-wrapper {
  --gap: 1.875rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

.my-equipments .device-wrapper > * {
  margin: var(--gap) 0 0 var(--gap);
}

.my-equipments .device-wrapper > div {
  flex: 1 1 calc(33.3% - var(--gap));
  display: flex;
  flex-direction: row;
}


@media (min-width: 1600px) {
  .my-equipments .device-wrapper > div:last-child {
    flex: 0 1 calc(33.3% - var(--gap));
  }
}

@media only screen and (max-width: 1400px) {
  .my-equipments .device-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .my-equipments .device-wrapper {
    --gap: 1.25rem;
    grid-template-columns: 1fr;
  }

  .my-equipments .device-wrapper > div {
    flex: 1 1 100%;
  }
}

@media only screen and (max-width: 425px) {
  .my-equipments .device-wrapper > div {
    flex-direction: column;
  }
}
