.list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-container .filters {
  display: flex;
  align-items: center;
}

.list-container .filters .search-input {
  min-width: 13.75rem;
}

.list-container .employee-list .ms-DetailsRow {
  cursor: pointer;
}

.list-container .empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.list-container .employee-list .mail {
  font-size: 0.75rem;
  color: var(--stone-gray);
  line-height: normal;
}

@media only screen and (max-width: 425px) {
  .list-container .filters .search-input {
    width: 100%;
  }
}
