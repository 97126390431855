.nav-icon {
    background-color: var(--white);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.nav-icon:hover .menu-icon-wrapper svg path {
    fill: var(--n4it-gray);
}

.nav-icon:hover .menu-icon-wrapper {
    color: var(--n4it-gray);
} 

.nav-icon.selected .menu-icon-wrapper svg path {
    fill: var(--n4it-gray);
}

.nav-icon.selected .menu-icon-wrapper {
    color: var(--n4it-gray);
}

.nav-icon .menu-icon-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--stone-gray);
}

.nav-icon .menu-icon-wrapper svg{
    margin: 0 auto;
}

.nav-icon .menu-icon-wrapper svg path{
    fill: var(--stone-gray);
}


@media only screen and (max-width: 768px) {
    .nav-icon .menu-icon-wrapper {
        flex-direction: row;
        align-items: center;
    }

    .nav-icon .menu-icon-wrapper svg{
        margin: 0 1.25rem 0 0;
    }
  }