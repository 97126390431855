.secondary-btn {
    background-color: var(--n4it-gray);
    color: var(--white);
    border: none;
}

.secondary-btn:hover:enabled {
    opacity: .8;
    background-color: var(--n4it-gray);
    color: var(--white);
    border: none;
}