.navbar {
    background-color: var(--white);
    width: 7.5rem;
    min-height: 100%;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
}

.navbar .logo {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;
}

.navbar .logo:hover {
    opacity: .7;
}

.navbar .logo a {
    width: 3.125rem;
}

.navbar .logo a svg{
    width: 100%;
    height: auto;
}

.navbar ul {
    margin-top: 3rem;
    list-style: none;
    padding: 0;
}

.navbar ul li {
    display: flex;
    justify-content: center;
}

.navbar ul li a {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.25rem .9375rem;
    text-decoration: none;
    font-size: .6875rem;
    font-weight: 600;
}
