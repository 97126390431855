.add-project-popup {
  width: 100%;
}

.add-project-popup .card-header .titles .subtitle {
  display: flex;
  font-size: 12px;
  color: var(--stone-gray);
  margin-left: 0.75rem;
}

.add-project-popup .content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 25rem;
  min-height: 25rem;
  margin: 0;
  padding: 0;
}

.add-project-popup .content .ms-Toggle {
  align-self: flex-start;
}

.add-project-popup .content .add-project {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 9.6875rem); 
  column-gap: 1.25rem;
  margin-bottom: 0.9375rem;
}

.add-project-popup .content .add-activity .add-activity-inputs {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 9.6875rem); 
  column-gap: 1.25rem;
  align-items: end;
}

.add-project-popup .content .add-activity .add-activity-inputs .secondary-btn {
  margin-bottom: 0.9375rem;
}

.add-project-popup .content .add-activity .add-activity-inputs .secondary-btn .ms-Button-textContainer {
  flex-grow: 0;
}

.add-project-popup .content .add-activity .ms-Viewport {
  margin-top: 0;
}

.add-project-popup .content .add-activity .ms-DetailsHeader,
.add-project-popup .content .add-activity .ms-DetailsRow-cell {
  height: 2.75rem;
}

.add-project-popup .content .add-activity .activity-info {
  display: flex;
  flex-direction: column;
}

.add-project-popup .content .add-activity .activity-info .activity-code {
  font-size: 0.75rem;
  color: var(--stone-gray);
  line-height: normal;
  margin-top: -0.3rem;
}

.add-project-popup .content .add-activity .ms-Viewport button {
  color: var(--n4it-gray);
}

.add-project-popup .content .add-activity .empty {
  color: var(--stone-gray);
  font-size: .75rem;
}

.add-project-popup .action-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 9.6875rem);
  column-gap: 1.25rem;
  margin-top: 2.5rem;
}

.add-project-popup .action-btns .primary-btn {
  grid-column: 4;
}

.add-project-popup .action-btns .tertiary-btn {
  grid-column: 3;
}

@media only screen and (max-width: 768px) {
  .add-project-popup .card-header .titles .subtitle {
    margin-left: 0;
  }

  .add-project-popup .content {
    height: 60vh;
    min-height: 60vh;
  }

  .add-project-popup .content .add-project {
    grid-template-columns: repeat(1, 100%);
  }

  .add-project-popup .content .add-activity .add-activity-inputs {
    grid-template-columns: repeat(1, 100%);
  }

  .add-project-popup .action-btns {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "label label";
  }

  .add-project-popup .action-btns .primary-btn {
    grid-column: auto;
  }

  .add-project-popup .action-btns .tertiary-btn {
    grid-column: auto;
  }
}
