.docslist-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-top: .625rem;
    height: 100%;
}

.docslist-container .ms-Viewport {
    flex: 1;
}

@media only screen and (max-width: 1024px) {
    .docslist-container {
        width: 100%;
        flex-direction: column;
        padding-top: 1.5625rem;
    }
}