.manager-calendar {
    display: flex;
    flex-direction: row;
    min-height: 100%;
}

.manager-calendar h1 {
    margin: 0;
}

.manager-calendar .selected {
    background-color: var(--lilia-gray);
}

.manager-calendar .selected > div {
    background-color: unset;
}

.manager-calendar .calendar-employee-list {
    min-width: 21.5rem;
    max-width: 21.5rem;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.manager-calendar .calendar-employee-list .search-box-container {
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column;
}

.manager-calendar .calendar-employee-list .search-box-container .search-box {
    display: flex;
    flex-direction: row;
    position: relative;
}

.manager-calendar .calendar-employee-list .search-box-container .search-box .ms-TextField {
    margin: 0;
    flex: 1;
}

.manager-calendar .calendar-employee-list .search-box-container .search-box .icon-btn {
    position: absolute;
    right: 0;
    border-radius: 0 .3125rem .3125rem 0;
    border: 0;
}

.manager-calendar .calendar-employee-list .list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: .375rem;
    padding-bottom: 1.375rem;
}

.manager-calendar .calendar-employee-list .previous, .manager-calendar .calendar-employee-list .next  {
    color: var(--n4it-gray);
    margin-left: .625rem;
}

.manager-calendar .calendar-employee-list .previous i, .manager-calendar .calendar-employee-list .next i {
    font-size: .75rem;
}

.manager-calendar .calendar-employee-list .ms-Viewport {
    margin-top: 0;
    overflow-y: auto;
}

.manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsRow {
    cursor: pointer;
}

.manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsRow-cell {
    height: 3.25rem;
}

/* .manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsList > div {
    overflow-y: auto;
    overflow-x: hidden;
}

.manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsList .ms-DetailsList-contentWrapper {
    height: unset;
} */

/* .manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsList, .manager-calendar .calendar-employee-list .ms-Viewport .ms-DetailsList > div {
    height: 100%;
}

.manager-calendar .calendar-employee-list .ms-DetailsRow-cellCheck, .manager-calendar .calendar-employee-list .ms-DetailsHeader-cellIsCheck {
    display: none;
}

.manager-calendar .calendar-employee-list .ms-DetailsRow {
    width: 100%;
}

.manager-calendar .calendar-employee-list .ms-DetailsRow-fields {
    min-width: 100%;
}

.manager-calendar .calendar-employee-list .approved-days, .ms-Callout .approved-days {
    height: 1.4rem;
    padding: 0 .6rem;
    display: flex;
    align-items: center;
    color: var(--white);
    border-radius: 3.125rem;
    background-color: #F1D959;
}

.ms-Callout .approved-days {
    font-size: .75rem;
    height: 1.2rem;
    padding: 0rem 0.4rem 0.1rem 0.4rem;
    margin-left: 1rem;
} */

.manager-calendar .calendar-card {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 4;
}

.calendar-card .calendar {
    width: 100%;
}

.calendar-card .calendar-commandbar {
    padding-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar-card .calendar-commandbar .employee {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.calendar-card .calendar-commandbar .employee .ms-Persona-primaryText{
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.875rem;
}

.calendar-card .calendar-commandbar .action-btns {
    display: flex;
}

.calendar-card .calendar-commandbar .action-btns .icon-btn:hover path {
    fill: var(--white);
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar {
    padding: 0;
    height: 2.3rem;
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
    border-radius: .3125rem;
    min-width: 2.3rem;
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled {
    background: none;
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled i {
    color: var(--stone-gray);
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled path {
    fill: var(--stone-gray);
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar i {
    color: var(--n4it-gray);
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .approve i {
    color: green;
}

.calendar-card .calendar-commandbar .action-btns .ms-CommandBar .decline i {
    color: red;
}

@media only screen and (max-width: 1200px) {
    .manager-calendar {
        flex-direction: column;
    }

    .manager-calendar {
        height: auto;
    }

    .manager-calendar .calendar-employee-list {
        max-width: unset;
    }

    .manager-calendar .calendar-employee-list .ms-ComboBox-container {
        margin-bottom: 0;
    }

    .manager-calendar .calendar-employee-list .list-header {
        padding-bottom: .75rem;
    }

    .calendar-card {
        min-height: unset;
        height: unset;
    }

    .calendar-card .calendar-commandbar .action-btns .primary-btn, .calendar-card .calendar-commandbar .action-btns .tertiary-btn {
        min-width: auto;
        margin-left: .625rem;
    }

    .calendar-card .calendar-commandbar .action-btns .primary-btn .ms-Button-textContainer{
        display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .manager-calendar .calendar-employee-list {
        min-width: unset;
    }

    .calendar-card .calendar-commandbar .employee {
        width: 100%;
    }

    .calendar-card .calendar-commandbar .employee .ms-Persona-primaryText {
        font-size: 1.25rem;
    }
}