.mydocs-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: 100%;
}

.mydocs-wrapper .upload-wrapper {
    min-width: 33.3%;
}

.mydocs-wrapper .upload-wrapper .upload {
    width: 100%;
    height: 12.5rem;
    padding: .625rem;
    background-color: rgba(234, 234, 234, .2);
}

.mydocs-wrapper .upload-wrapper .upload .upload-area {
    width: 100%;
    height: 100%;
    background-color: rgba(234, 234, 234, .4);
    border: 1px solid var(--stone-gray);
    border-style: dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mydocs-wrapper .upload-wrapper .upload .upload-area .upload-text {
    font-size: .75rem;
}

@media only screen and (max-width: 1024px) {
    .mydocs-wrapper {
        flex-direction: column;
    }
}