 .side-panel-form {
    position: relative;
    overflow-y: auto;
    height: 100%;
}

.add-equipment-content .side-panel-form .secondary-btn {
    width: 100%;
    justify-content: center;
}

.add-equipment-content .side-panel-form .secondary-btn .ms-Button-textContainer {
    flex-grow: 0;
}

.photos-list-empty {
    font-size: .75rem;
    color: var(--stone-gray);
    margin-top: .625rem;
}

.add-equipment-content .side-panel-form .ms-Viewport {
    margin-top: 0;
    overflow-y: unset;
}

.add-equipment-content .side-panel-form .photos-list.ms-DetailsList {
    margin-top: .625rem;
}

.add-equipment-content .side-panel-form .photos-list .img-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .3125rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.add-equipment-content .side-panel-form .photos-list i {
    color: var(--n4it-gray);
}

.add-equipment-content .side-panel-form .photos-list i:hover {
    opacity: .7;
}

.add-equipment-content .action-btns {
    display: flex;
    margin-top: 3.125rem;
}

.add-equipment-content .tertiary-btn, .add-equipment-content .primary-btn {
    flex: 1;
}

.add-equipment-content .tertiary-btn {
    margin-right: 1.25rem;
}

.upload-btn-wrapper .inputfile {
    width: .0063rem;
    height: .0063rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.upload-btn-wrapper .inputfile + .upload-btn-label {
    width: 100%;
    height: 2.3rem;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .3125rem;
    cursor: pointer;
    color: var(--white);
    background-color: var(--n4it-gray);
}

.upload-btn-wrapper .inputfile + .upload-btn-label:hover {
    opacity: .8;
}

.upload-btn-wrapper .inputfile + .upload-btn-label svg {
    width: .75rem;
    height: auto;
    margin-right: .625rem;
}
