.session-expired-page {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.session-expired-page .wrapper {
    padding: 2rem;
}

.session-expired-page .wrapper .error-text .error {
    opacity: .5;
}

.session-expired-page .wrapper .error-text .error svg {
    width: 8.75rem;
    height: auto;
}

.session-expired-page .wrapper .error-text .info {
    color: var(--white);
    padding: 1.625rem 0 2rem 0;
}



.session-expired-page .wrapper .error-text .info .title {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: .625rem;
}

.session-expired-page .wrapper .error-text .info .text {
    font-size: 1rem;
}

.session-expired-page .wrapper .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.session-expired-page .wrapper .action-btns .session-expired-page-secondary-btn {
    background-color: var(--white);
    color: var(--n4it-gray);
    margin-bottom: 0;
    width: 100%;
}


.session-expired-page .wrapper .action-btns .session-expired-page-secondary-btn:hover {
    background: var(--n4it-gray);
    color: var(--white);
}


.session-expired-page .wrapper .action-btns .session-expired-page-tertiary-btn {
    border: .0625rem solid var(--white);
    color: var(--white);
    width: 100%;
}

.session-expired-page .wrapper .action-btns .session-expired-page-tertiary-btn:hover {
    background: var(--white);
    color: var(--n4it-gray);
}

@media only screen and (max-width: 768px) {
    .session-expired-page .wrapper .error-text .error svg {
        width: 5.75rem;
        height: auto;
    }

    .session-expired-page .wrapper .error-text .info .title {
        font-size: 1.375rem;
    }

    .session-expired-page .wrapper .error-text .info .text {
        font-size: .875rem;
    }
}

@media only screen and (max-width: 425px) {
    .session-expired-page .wrapper .action-btns {
        flex-direction: column-reverse;
    }

    .session-expired-page .wrapper .action-btns .btn-margin-left {
        margin: 0;
    }

    .session-expired-page .wrapper .action-btns .session-expired-page-secondary-btn {
        margin-bottom: 20px;
    }
}
