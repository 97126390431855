.subordinates-employees-info-content {
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding-right: .625rem;
}

.subordinates-employees-info .card-header .titles {
    flex-direction: column;
}

.subordinates-employees-infoo .card-header .titles .subtitle {
    font-size: 12px;
    color: var(--stone-gray);
}

.subordinates-employees-info .card-header .titles .subtitle.holiday {
    color: #dd9d00;
}

.subordinates-employees-info .card-header .titles .subtitle.created {
    color: var(--stone-gray);
}

.subordinates-employees-info .subordinates-employees-info-content h2 {
    margin: 0 0 .9375rem 0;
}






.subordinates-employees-info .subordinates-employees-info-content .working-time .blocks .info-block:first-child {
    margin-right: 3rem;
}

.subordinates-employees-info .subordinates-employees-info-content .working-time .info-block {
    margin-bottom: .625rem;
}

.subordinates-employees-info .subordinates-employees-info-content .employee-photo{
    margin-bottom: .75rem;
}






