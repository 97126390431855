.add-group-popup {
  width: 100%;
}

.add-group-popup .content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 25rem;
  min-height: 25rem;
  margin: 0;
  padding: 0;
}

.add-group-popup .content .group-details .group-details-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-group-popup .content .group-details .group-details-inputs .textfield {
  flex: 1;
  min-width: 340px;
}

.add-group-popup .content .group-details .group-details-inputs .textfield.code {
  margin-right: 20px;
}

.add-group-popup .content .group-details {
  margin-bottom: 1.5rem;
}

.add-group-popup .content .group-personas {
  display: flex;
  flex-direction: row;
}

.add-group-popup .content .group-personas .group-personas-approver,
.add-group-popup .content .group-personas .group-personas-member {
  flex: 1;
  min-width: 340px;
}

.add-group-popup .content .group-personas .group-personas-approver {
  margin-right: 20px;
}

.add-group-popup .content .group-personas .group-personas-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-group-popup .content .group-personas .group-personas-inputs .select-persona {
  flex: 1;
  margin-right: 10px;
}

.add-group-popup .content .group-personas .group-personas-approver .group-personas-inputs .add-btn,
.add-group-popup .content .group-personas .group-personas-member .group-personas-inputs .add-btn {
  border: 0.0625rem solid var(--n4it-gray);
  background-color: var(--n4it-gray);
  color: white;
}

.add-group-popup .content .group-personas .group-personas-approver .group-personas-inputs .add-btn:hover:enabled,
.add-group-popup .content .group-personas .group-personas-member .group-personas-inputs .add-btn:hover:enabled {
  opacity: 0.8;
}

.add-group-popup .content .group-personas .group-personas-approver .selected-personas-empty,
.add-group-popup .content .group-personas .group-personas-member .selected-personas-empty {
  font-size: 0.75rem;
  color: var(--stone-gray);
}

.add-group-popup .content .group-personas .ms-Viewport {
  margin-top: unset;
}

.add-group-popup .action-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(4, 10rem); /*TEMPORÁRIO: é preciso tratar da responsividade dos action buttons*/
  column-gap: 1.25rem;
  margin-top: 1.5rem;
}

.add-group-popup .action-btns .tertiary-btn {
  grid-column: 3;
}

.add-group-popup .action-btns .primary-btn {
  grid-column: 4;
}

.add-group-popup .content .group-personas .ms-DetailsHeader,
.add-group-popup .content .group-personas .ms-DetailsRow-cell {
  height: 2.75rem;
}

.add-group-popup .content .group-personas .ms-Viewport button {
  color: var(--n4it-gray);
}

@media only screen and (max-width: 768px) {
  .add-group-popup .action-btns {
    grid-template-columns: repeat(2, 1fr);
  }

  .add-group-popup .action-btns .primary-btn {
    grid-column: auto;
  }

  .add-group-popup .action-btns .tertiary-btn {
    grid-column: auto;
  }
}
