.error-page {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.error-page .wrapper {
    padding: 2rem;
    width: 50%;
}

.error-page .error-text .error {
    font-size: 15rem;
    font-weight: 900;
    line-height: 11rem;
    color: var(--white);
    opacity: .4;
}

.error-page .error-text .info {
    color: var(--white);
    padding: 1.625rem 0 2rem 0;
}

.error-page .error-text .info .title {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .625rem;
}

.error-page .error-text .info .text {
    font-size: 1rem;
}

.error-page .error-page-btn {
    border: .0625rem solid var(--white);
    color: var(--white);
    width: 8.75rem;
}

.error-page .error-page-btn:hover {
    background: var(--white);
    color: var(--n4it-gray);
}

@media only screen and (max-width: 1024px) {
    .error-page  .wrapper {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .error-page .error-text .error {
        font-size: 7.5rem;
        line-height: 6rem;
    }

    .error-page .error-text .info .title {
        font-size: 1.375rem;
    }

    .error-page .error-text .info .text {
        font-size: .875rem;
    }
}

@media only screen and (max-width: 425px) {
    .error-page  .wrapper {
        width: 100%;
    }

    .error-page .error-page-btn {
        width: 100%;
    }
}
