.is-disabled {
  pointer-events: none;
}

/********* Textfields *********/
.ms-TextField {
  margin-bottom: 0.9375rem;
}

.ms-TextField.is-disabled .ms-TextField-fieldGroup {
  border: none;
}

.ms-TextField.no-margin {
  margin-bottom: 0;
}

.ms-TextField-fieldGroup {
  min-height: 2.3rem;
  border-color: var(--stone-gray);
  border-radius: 0.3125rem;
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.ms-TextField-fieldGroup::after {
  border: 0.0625rem solid var(--n4it-gray);
  border-radius: 0.3125rem;
}

.ms-TextField-fieldGroup > input {
  border-radius: 0.3125rem;
  padding: 0.624rem;
}

.ms-TextField-fieldGroup > input:disabled {
  border: none;
}

.ms-TextField-fieldGroup > textarea {
  padding: 0.624rem;
}

.ms-TextField-fieldGroup > i {
  position: unset;
  transform: scaleX(-1);
  color: var(--stone-gray);
  padding: 0 0.624rem 0 0;
}

@media only screen and (max-width: 1024px) {
  .hide-textField {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .ms-TextField {
    margin-bottom: 0.5rem;
  }
}

/********* Dropdown *********/
.ms-Dropdown {
  margin-bottom: 0.9375rem;
}

.ms-Dropdown:focus::after {
  border: 0.0625rem solid var(--n4it-gray);
  border-radius: 0.3125rem;
}

.ms-Dropdown .ms-Dropdown-title {
  border-radius: 0.3125rem;
  min-height: 2.3rem;
  padding: 0.14rem 1.2rem 0.14rem 0.625rem;
  border-color: var(--stone-gray);
}

.ms-Dropdown .ms-Dropdown-caretDownWrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
}

@media only screen and (max-width: 768px) {
  .ms-Dropdown {
    margin-bottom: 0.5rem;
  }
}

/********* ComboBox *********/
.ms-ComboBox {
  min-height: 2.3rem;
  margin-bottom: 0.9375rem;
  padding-left: 0.625rem;
}

.ms-ComboBox::after {
  display: flex;
  align-items: center;
  border: 0.0625rem solid var(--stone-gray);
  border-radius: 0.3125rem;
}

.ms-ComboBox-optionsContainerWrapper {
  max-height: 18.75rem;
  overflow-y: scroll;
}

/********* Datepicker *********/
.ms-DatePicker .ms-TextField-fieldGroup {
  flex-direction: row;
  padding: 0 0.624rem;
}

.ms-DatePicker .ms-TextField-fieldGroup .ms-TextField-field {
  display: flex;
  align-items: center;
  padding: 0 0.624rem 0 0;
}

/********* Checkbox *********/
.ms-Checkbox {
  margin-bottom: 0.625rem;
}

.ms-Checkbox .ms-Checkbox-checkbox {
  border: 0.0625rem solid var(--stone-gray);
}

/********* ContextualMenu *********/
.ms-Callout,
.ms-Callout-main {
  border-radius: 0.3125rem !important;
  max-height: 300px !important;
}

/********* BasePicker *********/
.ms-BasePicker {
  margin-bottom: 0.9375rem;
}

.ms-BasePicker .ms-BasePicker-text {
  min-height: 2.3rem;
  border-color: var(--stone-gray);
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
}

.ms-BasePicker .ms-BasePicker-text::after {
  border: 0.0625rem solid var(--n4it-gray);
  border-radius: 0.3125rem;
}

.ms-BasePicker .ms-BasePicker-text .ms-BasePicker-input {
  align-self: center;
  border-radius: 0.3125rem;
  padding: 0.624rem 0.624rem;
}

/********* Breadcrumb *********/
.ms-Breadcrumb {
  margin: 0;
  margin-left: -0.5rem;
}

/********* Spinner *********/
.ms-Spinner {
  height: 100%;
}

.ms-Spinner .ms-Spinner-circle {
  border-color: var(--stone-gray) var(--pearl-gray) var(--pearl-gray);
}

/********* Toggles *********/
/* .ms-Toggle button {
    background: var(--n4it-gray);
} */
