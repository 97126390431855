.spacer {
    flex: none;
}

.spacer.vertical {
    height: 1.5625rem;
}

.spacer.horizontal {
    width: 1.5625rem;
}

@media only screen and (max-width: 768px) {
    .spacer.vertical {
        height: 1.25rem;
    }

    .spacer.horizontal {
        width: 1.25rem;
    }
}