.card-background {
    background-color: var(--white);
    box-shadow: 0 .375rem .9375rem .3125rem rgba(0, 0, 0, 0.04);
    border-radius: .625rem;
    padding: 2.0625rem 2.5rem;
    box-sizing: border-box;
}

@media only screen and (max-width: 1440px) {
    .card-background {
        padding: 1.5625rem 2.1875rem;
    }
  }

@media only screen and (max-width: 425px) {
    .card-background {
        padding: 1.5rem;
    }
}
