.calendar, .calendar-mini {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
}

.calendar .calendar-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.calendar .calendar-header .month-btns {
    display: flex;
    justify-content: end;
}

.calendar .calendar-header .month-btns .ms-Button {
    color: var(--n4it-gray);
    margin-left: .625rem;
}

.calendar .calendar-header .month-btns .ms-Button i {
    font-size: .75rem;
}

.calendar .calendar-container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 3.125rem repeat(6, 7.8rem);
    border: .0625rem solid var(--pearl-gray);
}

.calendar .calendar-container .day-of-week {
    background-color: var(--pearl-gray);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item.empty {
    background-color: unset;
    pointer-events: none;
    position: relative;
}

.item {
    border: .0313rem solid var(--pearl-gray);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item:hover {
    cursor: pointer;
    border: .0313rem solid var(--n4it-gray);
}

.item.weekend, .item-allocate.weekend {
    background: repeating-linear-gradient( 45deg, transparent, transparent 8px, rgba(0, 0, 0, 0.03) 0, rgba(0, 0, 0, 0.03) 22px );
}

.item.holiday, .item-allocate.holiday {
    background: repeating-linear-gradient( 45deg, transparent, transparent 8px, rgba(221,157,0,0.04) 0, rgba(221,157,0,0.04) 22px );
}

.item.employee, .item.manager {
    background-color: var(--lilia-gray);
}

.item.pending {
    background-color: var(--lilia-gray);
}
.item.declined {
    background-color: #FFEEEE;
    border-color: #FFE1E1;
}
.item.approved {
    background-color: #F0FFF0;
    border-color: #DFF4DE;
    cursor: default;
}
.item.approved .projects {
    opacity: .6;
}
.item.border, .item-allocate.border {
    border: .0625rem solid var(--stone-gray);
}
.item.disable {
    pointer-events: none;
}

.holiday-label {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 10px;
    line-height: 1;
    /* color: var(--stone-gray); */
    /* color: var(--n4it-pink); */
    color: #dd9d00;
}

.day.holiday {
    color: #dd9d00;
}

@media only screen and (max-width: 1200px) {
    .calendar .calendar-container {
        grid-template-rows: 2.125rem repeat(6, 6rem);
    }
  }

@media only screen and (max-width: 1440px) {
    .item {
        padding: 0.8rem;
    }
}

/*////////////////////////////////////////////////////  Mini Calendar  //////////////////////////////////////////////////////////////*/

.calendar-mini .calendar-header {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    margin-top: 1.875rem;
    margin-bottom: .625rem;
}

.calendar-mini .calendar-header .month {
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.calendar-mini .calendar-header .month-btns .ms-Button {
    position: absolute;
    top: -0.375rem;
    color: var(--n4it-gray);
}

.calendar-mini .calendar-header .month-btns .ms-Button i{
    font-size: .625rem;
}

.calendar-mini .calendar-header .month-btns .ms-Button.previous {
    left: 0;
}

.calendar-mini .calendar-header .month-btns .ms-Button.next {
    right: 0;
}

.calendar-mini .calendar-container {
    height: 100%;
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(7, 2.125rem); */
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1.25rem repeat(6, 2.125rem);
    gap: .375rem;
    justify-self: center;
    font-size: .75rem;
    line-height: 1.25rem;
}

.calendar-mini .calendar-container .day-of-week {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--stone-gray);
}

.calendar-mini .calendar-container .item, .calendar-mini .calendar-container .item-allocate {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .3125rem;
    padding: 0;
    background-color: unset;
    border: none;
}

.calendar-mini .calendar-container .item.weekend, .calendar-mini .calendar-container .item-allocate.weekend {
    background: repeating-linear-gradient( 45deg, transparent, transparent 4px, rgba(0, 0, 0, 0.03) 0, rgba(0, 0, 0, 0.03) 16px );
}

.calendar-mini .calendar-container .item.holiday, .calendar-mini .calendar-container .item-allocate.holiday {
    background: repeating-linear-gradient( 45deg, transparent, transparent 4px, rgba(221,157,0,0.04) 0, rgba(221,157,0,0.04) 16px );
}

.calendar-mini .calendar-container .item.employee, .calendar-mini .calendar-container .item.manager {
    background-color: var(--lilia-gray);
}

.calendar-mini .calendar-container .item.pending {
    background-color: var(--lilia-gray);
}
.calendar-mini .calendar-container .item.declined {
    background-color: #FFEEEE;
    border-color: #FFE1E1;
}
.calendar-mini .calendar-container .item.approved {
    background-color: #F0FFF0;
    border-color: #DFF4DE;
    cursor: default;
}
.calendar-mini .calendar-container .item.approved .item-info {
    opacity: .6;
}
.calendar-mini .calendar-container .item.clone-day-disable {
    background-color: var(--n4it-gray);
    color: var(--white);
    pointer-events: none;
}
.calendar-mini .calendar-container .item.disable {
    pointer-events: none;
}
.calendar-mini .calendar-container .item.border, .calendar-mini .calendar-container .item-allocate.border {
   border: .0625rem solid var(--stone-gray);
}

.calendar-mini .calendar-container .item.selected {
    background-color: var(--n4it-pink);
    color: var(--white);
}

.calendar-mini .calendar-container .item.selected-not-empty {
    background-color: var(--n4it-pink);
    color: var(--white);
    text-decoration: underline;
}

.calendar-mini .calendar-container .item.notCurrent {
    opacity: .2;
    pointer-events: none;
}

@media (hover:hover) {
    .calendar-mini .calendar-container .item:hover {
        cursor: pointer;
        background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
        color: var(--white);
    }
}

.calendar-mini .calendar-container .item .item-info, .calendar-mini .calendar-container .item-allocate .item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.calendar-mini .calendar-container .item .item-info .day, .calendar-mini .calendar-container .item-allocate .item-info .day {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.25rem;
    position: unset;
}

@media only screen and (max-width: 768px) {
    .calendar-mini .calendar-header {
        margin-top: 0;
    }
}

/*////////////////////////////////////////////////////  Calendar Loading  //////////////////////////////////////////////////////////////*/

  .calendar-loading {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 2;
    grid-row-end: 8;
    display: flex;
    justify-content: center;
    align-items: center;
  }


