.edit-equipment-content .side-panel-form .ms-Viewport {
    margin-top: 0;
    overflow-y: unset;
}

.edit-equipment-content .side-panel-form .photos-list .img-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .3125rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.edit-equipment-content .secondary-btn {
    width: 100%;
    justify-content: center;
}

.edit-equipment-content .secondary-btn .ms-Button-textContainer {
    flex-grow: 0;
}

.edit-equipment-content .photos-list.ms-DetailsList {
    margin-top: .625rem;
}

.edit-equipment-content .photos-list .img-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .3125rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.edit-equipment-content .photos-list i {
    color: var(--n4it-gray);
}

.edit-equipment-content .photos-list i:hover {
    opacity: .7;
}

.edit-equipment-content .action-btns {
    display: flex;
    margin-top: 3.125rem;
}

.edit-equipment-content .tertiary-btn, .edit-equipment-content .primary-btn {
    flex: 1;
}

.edit-equipment-content .tertiary-btn {
    margin-right: 1.25rem;
}