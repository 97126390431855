.equipment-info h2 {
    font-size: 1.125rem;
    margin: 0;
}

.equipment-info .info-block {
    margin-bottom: .9375rem;
}

.equipment-info .no-photos {
    margin-bottom: 1.5rem;
    word-break: break-word;
    display: flex;
    justify-content: center;
    color: var(--stone-gray);
}

