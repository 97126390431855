.add-permission-popup {
  min-width: 20rem;
  min-height: 23rem;
}

.add-permission-popup .action-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
  margin-top: 1.5rem;
}
.add-permission-popup .employee-selection {
  margin-bottom: 1.5rem;
}

.add-permission-popup .action-btns .primary-btn {
  padding: 0 0.5rem;
}

@media only screen and (max-width: 425px) {
  .add-permission-popup {
    width: auto;
  }
}
