.device-container .device-icon-wrapper {
    padding-right: 1.25rem;
}

.device-container .device-icon-wrapper .device-icon {
    background-color: var(--stone-gray);
    border-radius: .5rem;
    color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4.0625rem;
    height: 4.0625rem;
    margin-bottom: 1.875rem;
}

.device-container .device-info {
    min-width: 12.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-left: 1.25rem;
}

.device-container .device-info .info-block{
    min-width: 50%;
    padding-right: .625rem;
}

@media only screen and (max-width: 425px) {
    .device-container .device-info {
        padding-left: 0;
    }
}