.permissions-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.permissions-container .commandbar .search-input {

  max-width: 14rem;
}

.permissions-container .employees-permissions-list .mail {
  font-size: 0.75rem;
  color: var(--stone-gray);
  line-height: normal;
}

.permissions-container .commandbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.permissions-container .employees-permissions-list .employee-info-container {
  display: flex;
}

.permissions-container .employees-permissions-list .employee-info-container .employee-photo {
  flex: 1;
}

.permissions-container .employees-permissions-list .employee-info-container .employee-photo:first-child {
  margin-right: -10px;
}
