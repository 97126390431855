.carousel-container {
  margin-bottom: 1.875rem;
}
  
  .selected-image {
    width: 100%;
    height: 15.625rem;
    margin-bottom: .3125rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: .0625rem solid var(--pearl-gray);
    border-radius: .3125rem;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel__images {
    display: flex;
    max-width: 100%;
    border-radius: 0 0 .3125rem .3125rem;
    overflow: hidden;
  }
  
  .carousel__image-selected {
    border: .0625rem solid var(--stone-gray) !important;
  }
  
  .carousel__image {
    cursor: pointer;
    margin-right: .3125rem;
    height: 5rem;
    min-width: 5.625rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: .0625rem solid var(--pearl-gray);
    border-radius: .3125rem;
  }
  
  .carousel__button {
    position: absolute;
    top: 32%;
    color: var(--pearl-gray);
    opacity: .7;
  }

  .carousel__button:hover {
    background: unset;
    color: var(--pearl-gray);
    opacity: 1;
  }
  
  .carousel__button-left {
    left: .3125rem;
  }
  
  .carousel__button-right {
    right: .3125rem;
    transform: rotate(180deg);
  }