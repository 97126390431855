.mobile-nav-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    height: 100%;
    padding: 1.5rem 1.5rem 1rem 1.5rem ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.mobile-nav-panel .panel-header {
    height: 4.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: .0625rem solid var(--pearl-gray);
}

.mobile-nav-panel .panel-header a {
    display: flex;
    width: 4rem;
    height: auto;
    margin-left: 1rem;
}

.mobile-nav-panel .panel-header a svg{
    width: 100%;
    height: auto;
}

.mobile-nav-panel .panel-header .close-icon{
    color: var(--n4it-gray);
}

.mobile-nav-panel .mobile-nav-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5rem;
}

.mobile-nav-panel .mobile-nav-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav-panel .mobile-nav-content ul li {
    display: flex;
    justify-content: center;
}

.mobile-nav-panel .mobile-nav-content ul li a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 1.5rem 1rem;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 600;
}

.mobile-nav-panel .mobile-nav-content .account {
    border-top: .0625rem solid var(--pearl-gray);
    padding-top: 2.5rem;
}

.mobile-nav-panel .mobile-nav-content .account .account-info {
    padding-left: 1rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile-nav-panel .mobile-nav-content .account .account-info .avatar {
    display: block;
    margin-right: 1.25rem;
}

.mobile-nav-panel .mobile-nav-content .account .account-info .account-data {
    color: var(--n4it-gray);
    word-break: break-word;
    line-height: 1rem;
}

.mobile-nav-panel .mobile-nav-content .account .account-info .account-data .name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: .25rem;
}

.mobile-nav-panel .mobile-nav-content .account .account-info .account-data .mail {
    font-size: .75rem;
    color: var(--stone-gray);
    line-height: .875rem;
}