.calendar-day-info-content {
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding-right: .625rem;
}

.calendar-day-info .card-header .titles {
    flex-direction: column;
}

.calendar-day-info .card-header .titles .subtitle {
    font-size: 12px;
    color: var(--stone-gray);
}

.calendar-day-info .card-header .titles .subtitle.holiday {
    color: #dd9d00;
}

.calendar-day-info .card-header .titles .subtitle.created {
    color: var(--stone-gray);
}

.calendar-day-info .calendar-day-info-content h2 {
    margin: 0 0 .9375rem 0;
}

.calendar-day-info .calendar-day-info-content .working-time {
    margin-bottom: 1.5625rem;
}

.calendar-day-info .calendar-day-info-content .working-time .blocks {
    display: flex;
}

.calendar-day-info .calendar-day-info-content .working-time .blocks .info-block {
    margin-bottom: 0;
}

.calendar-day-info .calendar-day-info-content .working-time .blocks .info-block:first-child {
    margin-right: 3rem;
}

.calendar-day-info .calendar-day-info-content .working-time .info-block {
    margin-bottom: .625rem;
}

.calendar-day-info .calendar-day-info-content .project-hours {
    margin-bottom: 1.5625rem;
}

.calendar-day-info .calendar-day-info-content .project-hours .ms-Viewport {
    margin-top: 0;
    pointer-events: none;
}

.calendar-day-info .calendar-day-info-content .project-hours .project-activity .activity {
    color: var(--stone-gray);
}

.calendar-day-info .calendar-day-info-content .notes {
    margin-bottom: 1.5625rem;
}

.calendar-day-info .calendar-day-info-content .note-info {
    color: var(--n4it-gray);
    font-weight: 400;
    font-size: .75rem;
    line-height: 1rem;
    margin-bottom: .75rem;
}

.calendar-day-info .calendar-day-info-content .notes .project {
    font-weight: 600;
    margin-bottom: .3125rem;
}

.calendar-day-info .calendar-day-info-content .notes .activity {
    color: var(--stone-gray);
    margin-left: .375rem;
    font-weight: 400;
}

.calendar-day-info .calendar-day-info-content .approved-by .ms-Persona {
    height: auto;
}

.calendar-day-info .calendar-day-info-content .history p {
    color: var(--n4it-gray);
    font-weight: 400;
    font-size: .75rem;
    line-height: 1rem;
}

.calendar-day-info .action-btns {
    display: flex;
    align-self: baseline;
    width: 100%;
    margin-top: 1.875rem;
}

.calendar-day-info .action-btns .primary-btn {
    flex-grow: 1;
    flex-basis: min-content;
}

.calendar-day-info .action-btns .primary-btn .ms-Button-textContainer {
    flex-grow: 0;
}

.calendar-day-info .calendar-day-info-content .history {
    margin-top: 1.5625rem;
}

