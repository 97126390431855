@import "./assets/css/colors.css";
@import "./assets/css/inputs.css";
@import "./components/buttons/buttons.css";
@import "./assets/css/lists.css";
@import "./assets/css/heights.css";

* {box-sizing: border-box;}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: var(--lilia-gray);
  width: 100%;
  height: 100vh;
  color: #48494A;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375rem;
}

#root {
  height: 100%;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--stone-gray) var(--pearl-gray);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: .4rem;
  height: .4rem;
}

*::-webkit-scrollbar-track {
  background: var(--pearl-gray);
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--stone-gray);
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



.menu-font-icon{
  font-size: 25px;
  align-self: center;
  margin-bottom: 5px;
}


.sharepoint-logo {
  align-self: center;
  margin-bottom: -0.10rem;
  margin-right: -0.20rem;
  height: 30px;
  width: 30px;
  font-size: 24px;
}
