/* .calendar .calendar-container .item.empty:hover {
    cursor: default;
    border: inherit;
} */

.item .item-info {
    justify-content: end;
}

.item.notCurrent > * {
    opacity: .2;
}