.mobile-nav-bar {
    width: 100%;
    height: var(--mobile-nav-height);
    background-color: var(--white);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    padding: 1.5rem;
}

.mobile-nav-bar a {
    width: 4rem;
    height: auto;
    order: 2;
}

.mobile-nav-bar a svg {
    width: 100%;
    height: auto;
}

.mobile-nav-bar .menu-icon {
    flex: 1 1 0;
    order: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mobile-nav-bar .menu-icon button {
    width: 2.5rem;
    height: 2.5rem;
}

.mobile-nav-bar .module-btn {
    flex: 1 1 0;
    order: 3;
    display: flex;
    justify-content: flex-end;
}

.mobile-nav-bar .module-btn .primary-btn {
    padding: 0 .5rem;
    min-width: 2.3rem;
    font-weight: 600;
}

.mobile-nav-bar .module-btn .primary-btn .ms-Button-label {
    font-weight: 600;
}

.mobile-nav-bar .module-btn .primary-btn i {
    display: none;
}