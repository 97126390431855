.popup {
    width: auto;
    min-width: 21rem;
    max-width: 47.25rem;
    background-color: var(--white);
    box-shadow: 0 .375rem .9375rem .3125rem rgba(0, 0, 0, 0.04);
    border-radius: .625rem;
    padding: 2em;
    box-sizing: border-box;
}

.popup-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .popup {
        min-width: 20.625rem;
        max-width: 24.625rem;
        padding: 1.5625rem;
    }
  }

@media only screen and (max-width: 425px) {
    .popup {
        width: 90%;
        min-width: unset;
    }
}