.personal-info .wrapper {
    display: flex;
    flex-direction: row;
}

.personal-info .wrapper .info-block:last-child {
    margin-bottom: 0;
}

.personal-info .wrapper .info-block .label {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.625rem;
    margin: 0 0 0.5rem 0;
}

.personal-info .wrapper .info-block .text {
    color: unset;
}

.personal-info .wrapper .primary-info, .secondary-info {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    padding-right: 2.5rem;
}

.personal-info .wrapper .primary-info {
    align-items: center;
}

.personal-info .wrapper .secondary-info div {
    flex: 1;
    align-items: flex-start;
}

.personal-info .wrapper .secondary-info div:first-child {
    padding-right: 2.5rem;
}

.personal-info .wrapper .primary-info {
    display: flex;
    flex-direction: row;
}

.personal-info .wrapper .primary-info .avatar {
    display: block;
    margin-right: 1.875rem;
}

.personal-info .wrapper .primary-info h1{
    margin-bottom: 0;
}

.personal-info .wrapper .primary-info .role {
    font-size: 1rem;
    color: var(--stone-gray);
}

@media only screen and (max-width: 1024px) {
    .personal-info .wrapper {
        flex-direction: column;
    }

    .personal-info .wrapper .info-block:last-child {
        margin-bottom: 1.5rem;
    }

    .personal-info .wrapper .primary-info, .secondary-info {
        padding-right: 0;
    }
    
    .personal-info .wrapper .primary-info {
        margin-bottom: 2rem;
        flex-basis: unset;
    }
}

@media only screen and (max-width: 768px) {
    .personal-info .wrapper .secondary-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .personal-info .wrapper .secondary-info div:first-child {
        padding-right: 0;
    }
}

@media only screen and (max-width: 425px) {
    .personal-info .wrapper .primary-info h1{
        margin-bottom: 0;
    }

    .personal-info .wrapper .primary-info .role {
        font-size: 1rem;
        color: var(--stone-gray);
    }

    .personal-info .wrapper .primary-info .avatar {
        margin-right: 1rem;
    }
}