.list-container .command-bar {
  display: flex;
  justify-content: space-between;
}

.list-container .filters {
  justify-content: flex-start;
}

.list-container .action-btns {
  display: flex;
  align-items: center;
}

.list-container .equipment-list .deactivated-equipment {
  background: repeating-linear-gradient(15deg, transparent, transparent 8px, rgba(128, 127, 127, 0.04) 0, rgba(128, 127, 127, 0.04) 22px);
}

.list-container .equipment-list .disabled {
  color: var(--stone-gray);
  opacity: 0.6;
}

.list-container .equipment-list .device-icon {
  background-color: var(--stone-gray);
  width: 2rem;
  height: 2rem;
  border-radius: 3.125rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-container .equipment-list .device-icon-disabled {
  background-color: var(--stone-gray);
  width: 2rem;
  height: 2rem;
  border-radius: 3.125rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

.list-container .equipment-list img {
  width: 2.5rem;
  height: auto;
  margin-right: 0.625rem;
  border-radius: 3.125rem;
}

.list-container .equipment-list .device-state {
  height: 1.6rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  color: var(--white);
  border-radius: 3.125rem;
}

.list-container .equipment-list .device-state-disabled {
  height: 1.6rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  color: var(--white);
  border-radius: 3.125rem;
  opacity: 0.6;
}

.list-container .equipment-list .allocation-employee .disabled {
  opacity: 0.4;
}

.approved {
  background-color: #f1d959;
}

.delivered {
  background-color: #78ba61;
}

.returned {
  background-color: var(--stone-gray);
}

.list-container .equipment-list .overflow-menu {
  display: flex;
}

.list-container .equipment-list .overflow-menu .ms-Icon {
  color: var(--n4it-gray);
  transform: rotate(90deg);
}

.width-info-sidepanel {
  width: 25rem;
}

/* .list-container .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
} */

/* .list-container .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
} */

/* .list-container .equipment-list .device-icon svg {
  width: 100%;
  height: auto;
} */
