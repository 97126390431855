.delete-hours-popup {
    text-align: center;
}

.delete-hours-popup .action-btns {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.delete-hours-popup .tertiary-btn, .delete-hours-popup  .primary-btn {
    flex: 1;
    max-width: 10rem;
}

.delete-hours-popup  .tertiary-btn {
    margin-right: 1.25rem;
}