.feedback-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    min-width: 300px;
}

.feedback-popup .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 1.25rem;
    opacity: 0.9;
}

.feedback-popup .feedback {
    width: 100%;
    text-align: center;
}

.feedback-popup .feedback h1 {
    margin-bottom: .8rem;
}

.feedback-popup .feedback .popup-text p {
    margin: 0;
}

.feedback-popup .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem;
}

.feedback-popup .action-btns .primary-btn, .feedback-popup .action-btns .secondary-btn, .feedback-popup .action-btns .tertiary-btn {
    min-width: 8.125rem;
    flex: 1;
}

.feedback-popup .action-btns .tertiary-btn {
    max-width: 10rem;
    margin-right: 1.25rem;
}

.feedback-popup .action-btns .success {
    background: #6cc659;
    opacity: 0.9;
}

.feedback-popup .action-btns .error {
    background: #ff5e5e;
    opacity: 0.9;
}

.feedback-popup .action-btns .warning {
    background: #FFB705;
    opacity: 0.9;
}

.feedback-popup .action-btns .success.is-disabled, .feedback-popup .action-btns .error.is-disabled, .feedback-popup .action-btns .warning.is-disabled {
    opacity: .7;
    background: var(--pearl-gray);
    color: var(--stone-gray);
    border: none;
    pointer-events: none;
}




@media only screen and (max-width: 425px) {
    .feedback-popup {
        min-width: unset;
    }

    .feedback-popup .action-btns {
        width: 100%;
    }

    .feedback-popup .action-btns .primary-btn,.feedback-popup .action-btns .secondary-btn, .feedback-popup .action-btns .tertiary-btn {
        min-width: unset;
    }
}