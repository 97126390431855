.side-panel {
    background-color: var(--white);
    box-shadow: 0px .375rem .9375rem .3125rem rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 0;
    right: 0;
    width: 25rem;
    min-width: 25rem;
    height: 100vh;
    padding: 2.5rem;
    animation: menu-panel 150ms linear;
    overflow-y: auto;
    z-index: 3;
}


@keyframes menu-panel {
    0% {
        width: 0;
        opacity: 0;
    }
  
    100% {
        width: 25rem;
        opacity: 1;
    }
  }

@media only screen and (max-width: 425px) {
    .side-panel {
        width: 100%;
        min-width: 100%;
        padding: 1.25rem;
    }

    @keyframes menu-panel {
        0% {
            width: 0;
            opacity: 0;
        }
      
        100% {
            width: 100%;
            opacity: 1;
        }
      }
}