.activity-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.activity-wrapper.disabled .ms-DetailsRow {
  pointer-events: none;
}
.activity-wrapper.disabled, .activity-wrapper.disabled .ms-DetailsHeader-cell, .activity-wrapper.disabled .ms-DetailsRow-cell, .activity-wrapper.disabled .ms-DetailsRow-cell .ms-Persona-primaryText, .activity-wrapper.disabled .ms-DetailsRow-cell .ms-Button, .activity-wrapper.disabled .ms-DetailsRow-cell .ms-Button i {
  color: var(--stone-gray);
}

.activity-wrapper.disabled .ms-DetailsRow-cell .ms-Persona-coin {
  opacity: .5;
}

.activity-wrapper .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
}

.activity-wrapper .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.activity-wrapper .activity-info-card {
  width: 100%;
  height: fit-content;
}

.activity-wrapper .activity-info-card .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activity-wrapper .activity-info-card .header h1 {
  margin-bottom: 0;
}

.activity-wrapper .activity-info-card .header h1 .disabled-icon {
  margin-right: .625rem;
  font-size: 1rem;
}

.activity-wrapper .activity-info-card .header .ms-Toggle {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

/* .activity-wrapper .activity-info-card .header .status-label {
  height: 1.6rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: var(--stone-gray);
  border-radius: 3.125rem;
  background-color: var(--pearl-gray);
} */

.activity-wrapper .activity-info-card .activity-info {
  display: flex;
  flex-direction: column;
}

.activity-wrapper .activity-info-card .activity-info .info-block {
  margin-right: 2.5rem;
  margin-bottom: 0;
}

.activity-employees-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.activity-employees-card .commandbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-employees-card .commandbar h1 {
  margin: 0;
}

.activity-employees-card .commandbar .action-btns {
  display: flex;
}

.activity-employees-card .commandbar .action-btns .icon-btn:hover path {
  fill: var(--white);
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar {
  padding: 0;
  height: 2.3rem;
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
  border-radius: 0.3125rem;
  min-width: 2.3rem;
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled {
  background: none;
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled i {
  color: var(--stone-gray);
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled path {
  fill: var(--stone-gray);
}

.activity-employees-card .commandbar .action-btns .ms-CommandBar i {
  color: var(--n4it-gray);
}

.activity-employees {
  width: 100%;
  display: grid;
  overflow: hidden;
  flex: 1;
}

.activity-employees .ms-Viewport {
  margin-top: 1rem;
}

.activity-employees .ms-DetailsHeader,
.activity-employees .ms-DetailsRow-cell {
  height: 2.75rem;
}

.activity-employees .ms-Viewport button {
  color: var(--n4it-gray);
}


@media only screen and (max-width: 768px) {
  .activity-info-card .activity-info {
    flex-direction: column;
  }

  .activity-info-card .activity-info .info-block {
    margin-right: 0;
  }

  .activity-info-card .activity-info .info-block:last-child {
    margin-bottom: 0;
  }

  .activity-employees .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
    background-color: var(--n4it-gray);
  }

  .activity-employees .commandbar .action-btns .ms-CommandBar i {
    color: var(--white);
    font-size: 14px;
  }
}
