.groups-list-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.groups-list-card .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
}

.groups-list-card .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.groups-list-card .commandbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groups-list-card .commandbar .action-btns {
  display: flex;
}

.groups-list-card .groups-list .ms-DetailsRow {
  cursor: pointer;
}

.groups-list-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
  border-radius: 0.3125rem;
  min-width: 2.3rem;
}

.groups-list-card .commandbar .action-btns .ms-CommandBar i {
  color: var(--n4it-gray);
}

.groups-list-card .groups-list .ms-DetailsRow-cell {
  height: 2.8rem;
}

.groups-list-card .groups-list .ms-DetailsRow-cell .disable {
  color: var(--stone-gray);
  opacity: 0.6;
}

.groups-list-card .groups-list .ms-Button--icon:hover {
  opacity: 0.7;
  background: none;
}

.groups-list-card .groups-list .ms-Button--icon i {
  color: var(--n4it-gray);
}

.groups-list-card .groups-list .ms-Button--icon.is-disabled {
  background: none;
}

.groups-list-card .groups-list .ms-Button--icon.is-disabled i {
  color: var(--stone-gray);
}
