.edit-activity-popup .activity {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 17.5rem;
}

.edit-activity-popup .activity.activity-info {
  display: flex;
  flex-direction: row;
}

.edit-activity-popup .activity .act-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
  margin-top: 2.5rem;
}
