.add-activity-popup {
  width: 17.5rem;
}

.add-activity-popup .action-btns {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
  margin-top: 2.5rem;
}

.add-activity-popup .action-btns .primary-btn {
  padding: 0 0.5rem;
}

@media only screen and (max-width: 425px) {
  .add-activity-popup {
    width: auto;
  }
}
