.list-container .command-bar .filters .secondary-btn .filters-counter {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.3125rem;
  border-radius: 3.125rem;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--n4it-gray);
}

.list-container .allocations-list .allocation-employee {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .list-container .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
}

.list-container .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
} */

.list-container .allocations-list .allocation-employee img {
  width: 2.5rem;
  height: auto;
  margin-right: 0.625rem;
  border-radius: 3.125rem;
}

.list-container .allocations-list .ms-Dropdown-container {
  border-radius: 3.125rem;
}

.list-container .allocations-list .ms-Dropdown {
  border: 0;
  margin: 0;
}

.list-container .allocations-list .ms-Dropdown:focus::after {
  border: 0;
}

.list-container .allocations-list .ms-Dropdown-container:hover {
  opacity: 0.8;
}

.list-container .allocations-list .ms-Dropdown .ms-Dropdown-title {
  height: 1.6rem;
  min-height: unset;
  padding: 0 1.7rem 0 0.6rem;
  border-radius: 3.125rem;
  background-color: unset;
  color: var(--white);
  border: 0;
  cursor: pointer;
  line-height: 1.7;
}

.list-container .allocations-list .ms-Dropdown-caretDownWrapper {
  padding: 0;
  min-height: unset;
  height: 100%;
}

.list-container .allocations-list .ms-Dropdown-caretDownWrapper i {
  color: var(--white);
  font-size: 0.625rem;
}

.approved {
  background-color: #f1d959;
}

.delivered {
  background-color: #78ba61;
}

.canceled {
  background-color: #ff5656;
}

.returned {
  background-color: var(--stone-gray);
}
.closed {
  background-color: cadetblue;
}

.list-container .allocations-list .history-btn {
  cursor: pointer;
  color: var(--n4it-gray);
}

.list-container .allocations-list .history-btn:hover {
  opacity: 0.7;
}


