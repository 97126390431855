.filters-panel .side-panel-form .action-btns {
  display: flex;
  margin-top: 3.125rem;
}
.filters-panel .side-panel-form .secondary-btn {
  flex: 1;
  justify-content: center;
}

.filters-panel .side-panel-form .tertiary-btn {
  margin-right: 1.25rem;
  flex: 1;
}

.filters-panel .side-panel-form .quick-templates .btns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filters-panel .side-panel-form .quick-templates .btns-container .btns {
  flex-grow: 1;
}

.filters-panel .quick-templates .line-separator {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  display: flex;
  border-bottom: 0.0625rem solid var(--pearl-gray);
  padding-bottom: 0.75rem;
}
