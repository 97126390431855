.n4u-splash {
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}



.n4u-splash-logo svg {
    height: auto;
    max-width: 15.625rem;

    stroke-dasharray: 518;
    stroke-dashoffset: 518;
    animation: .5s fillSvg ease-in-out;
    animation-fill-mode: forwards;
}


.retail .n4u-splash-logo svg {
    max-width: 45.625rem;

}


@keyframes fillSvg {
    0% {
        stroke-dashoffset: 518;
    }

    100% {
        stroke-dashoffset: 0;
    }
}


.n4u-splash-spinner {
    margin-top: -1.875rem;
    height: auto;
}

.n4u-splash-spinner .ms-Spinner-circle {
    border-color: rgba(255,255,255,.3) var(--pearl-gray) var(--pearl-gray);
}
