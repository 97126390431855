.number-input {
    position: relative;
}

.number-input .ms-TextField-fieldGroup:hover {
    border-color: var(--stone-gray);
}

.number-input .ms-TextField-fieldGroup:after {
    border-color: var(--stone-gray);
}

.number-input .disabled .ms-TextField-fieldGroup {
    border: none;
}

.number-input input[type=number] {
    caret-color: transparent;
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem;
    cursor: default;
    -moz-appearance:textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.number-input .increment, .number-input .decrement {
    position: absolute;
    top: 2rem;
    width: 2.3rem;
    height: 2.3rem;
    border: .0625rem solid var(--stone-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

.number-input .decrement {
    left: 0;
    border-radius: .3125rem 0 0 .3125rem;
}

.number-input .increment {
    right: 0;
    border-radius: 0 .3125rem .3125rem 0;
}

.number-input .increment:hover, .number-input .decrement:hover {
    background-color: var(--stone-gray);
    color: #ffffff;
}

.number-input .increment.disabled, .number-input .decrement.disabled {
    opacity: .5;
    border: none;
    pointer-events: none;
}

.number-input .increment.limit, .number-input .decrement.limit {
    opacity: .2;
    pointer-events: none;
}