.group-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.group-wrapper .group {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 28.75rem;
}

.group-wrapper .group .group-info-card .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group-wrapper .group .group-info-card .header h1 {
  margin-bottom: 0;
  word-break: break-word;
}

.group-wrapper .group .group-info-card .header h1 .disabled-icon {
  margin-right: 0.625rem;
  font-size: 1rem;
}

.group-wrapper .group .group-info-card .header .ms-Toggle {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.group-wrapper .group .group-info-card .group-info {
  display: flex;
  flex-direction: row;
}

.group-wrapper .group .group-info-card .group-info .info-block {
  margin-right: 2.5rem;
  margin-bottom: 0;
}

.group-wrapper .group .group-info-card .group-info .info-block:last-child {
  margin-right: 0;
}

.group-wrapper .group .group-approvers-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.group-wrapper .group .group-approvers {
  width: 100%;
  display: grid;
  overflow: hidden;
  flex: 1;
}

.group-wrapper .group .group-approvers .ms-Viewport {
  margin-top: 1rem;
}

.group-wrapper .group .group-approvers .ms-DetailsHeader,
.group-wrapper .group .group-approvers .ms-DetailsRow-cell {
  height: 2.75rem;
}

.group-wrapper .group .group-approvers .ms-Viewport button {
  color: var(--n4it-gray);
}

.group-wrapper .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  height: 100%;
}

.group-wrapper .empty .empty-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.group-members-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.group-members-card .commandbar,
.group-wrapper .group .group-approvers-card .commandbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-members-card .commandbar h1,
.group-wrapper .group .group-approvers-card .commandbar h1 {
  margin: 0;
}

.group-members-card .commandbar .action-btns,
.group-wrapper .group .group-approvers-card .commandbar .action-btns {
  display: flex;
}

.group-members-card .commandbar .action-btns .icon-btn:hover path,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .icon-btn:hover path {
  fill: var(--white);
}

.group-members-card .commandbar .action-btns .ms-CommandBar,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar {
  padding: 0;
  height: 2.3rem;
}

.group-members-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
  border-radius: 0.3125rem;
  min-width: 2.3rem;
}

.group-members-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled {
  background: none;
}

.group-members-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled i,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled i {
  color: var(--stone-gray);
}

.group-members-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled path,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar.is-disabled path {
  fill: var(--stone-gray);
}

.group-members-card .commandbar .action-btns .ms-CommandBar i,
.group-wrapper .group .group-approvers-card .commandbar .action-btns .ms-CommandBar i {
  color: var(--n4it-gray);
}

.group-members-card .wrapper {
  width: 100%;
  display: grid;
  overflow: hidden;
  flex: 1;
}

.group-members-card .members-list .ms-DetailsRow {
  cursor: pointer;
}

.group-members-card .members-list .ms-DetailsRow-cell {
  height: 2.8rem;
}

.group-members-card .members-list .ms-DetailsRow-cell .disable {
  color: var(--stone-gray);
  opacity: 0.6;
}

.group-members-card .members-list .ms-Button--icon:hover {
  opacity: 0.7;
  background: none;
}

.group-members-card .members-list .ms-Button--icon i {
  color: var(--n4it-gray);
}

.group-members-card .members-list .ms-Button--icon.is-disabled {
  background: none;
}

.group-members-card .members-list .ms-Button--icon.is-disabled i {
  color: var(--stone-gray);
}

@media only screen and (max-width: 768px) {
  .group-info-card .group-info {
    flex-direction: column;
  }

  .group-info-card .group-info .info-block {
    margin-right: 0;
  }

  .group-info-card .group-info .info-block:last-child {
    margin-bottom: 0;
  }

  .group-members-card .commandbar .action-btns .ms-CommandBar .ms-Button--commandBar {
    background-color: var(--n4it-gray);
  }

  .group-members-card .commandbar .action-btns .ms-CommandBar i {
    color: var(--white);
    font-size: 14px;
  }
}
