.icon-btn {
    background: none;
    color: var(--n4it-gray);
    border: .0625rem solid var(--n4it-gray);
    min-width: 2.3rem;
}

.icon-btn:hover:enabled {
    opacity: .8;
    background-color: var(--n4it-gray);
    color: blue;
}

.icon-btn.primary {
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    color: var(--white);
    border: none;
}

.icon-btn.primary:hover:enabled {
    opacity: .8;
    background: linear-gradient(180deg, var(--n4it-pink) 0%, rgba(234, 12, 71, 0.75) 100%);
    color: var(--white);
    border: none;
}

.icon-btn.secondary {
    background-color: var(--n4it-gray);
    color: var(--white);
    border: none;
}

.icon-btn.secondary:hover:enabled {
    opacity: .8;
    background-color: var(--n4it-gray);
    color: var(--white);
    border: none;
}