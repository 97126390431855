.allocation-history-panel .allocation-history-list .ms-DetailsHeader-cell {
     height: 2.5rem; 
}

.allocation-history-panel .allocation-history-list .ms-DetailsRow-cell {
    height: 2.5rem;
    margin-bottom: 0.5rem;
}

.allocation-history-panel .allocation-history-list .state-dot {
    width: .9375rem;
    height: .9375rem;
    border-radius: 3.125rem;
}